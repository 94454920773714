body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 15px !important; */
}

.ant-form-item-label label {
 font-weight: bold !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.cursor-pointer {
  cursor: pointer;
}

.h-500 {
  max-height: 500px;
}
.fees-summary-max-h-421 {
  max-height: 421px;
}

.billed-income-summary {
  max-height: 462px;
}

.background-image-report-watermark {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.15;
  position: absolute;
  left: 5%;
  right: 10%;
  width: 90%;
  height: 90%;
  top: 10%;
  z-index: 100;
}

.p-btn {
  /* display: flex;
  position: fixed;
  margin-left: 15; */
  /* background-color: white; */
  /* z-index:1000; */
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 1055;
}


.container-max-width {
    max-width: 85% !important;
}

.container-max-collapsed {
  max-width: 95% !important;
}

@media only screen and (min-width: 1000px) {
  .max-screen-vh {
    max-height: 79vh;
  }
}

/* dashboard cards*/
.main-dashboard-card {
  width: 18%;
}
.align-table-text-center {
  text-align: center; 
}

.report-table .ant-table-cell {
  border-left: solid 0.2px #7e7e7e !important;
  border-bottom: solid 0.2px #7e7e7e !important;
}

.report-table .ant-table-thead .ant-table-cell {
  border-top: solid 0.2px #7e7e7e !important;
}
.report-table .ant-table-cell:last-child {
  border-right: solid 0.2px #7e7e7e !important;
}

.report-table .ant-table-footer {
  margin-left: 1px;
  border-left: solid 0.2px #7e7e7e !important;
  border-bottom: solid 0.2px #7e7e7e !important;
  border-right: solid 0.2px #7e7e7e !important;
}

.timetable  .ant-table-tbody .ant-table-cell {
  border-top: solid 0.2px #7e7e7e !important;
  border-left: solid 0.2px #7e7e7e !important;
  border-bottom: solid 0.2px #7e7e7e !important;
  border-right: solid 0.2px #7e7e7e !important;
}

.report-table-small .ant-table {
  font-size: 12px !important;
}

.report-table-small .ant-table-tbody > tr > td {
  padding: 2px !important;
}

.report-table-small .ant-table-thead > tr > th {
  padding: 3px !important;
}

.report-table-small-no-header .ant-table-thead > tr > th {
  display: none !important;
}

.report-table-small .ant-table-footer  {
  padding: 2px !important;
}


@media only screen and (max-width: 500px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .system-applications-max {
    max-width: 93%;
  }
}

.timetable-table .ant-table-small .ant-table-cell .ant-table-thead > tr > th, 
.timetable-table .ant-table-small .ant-table-cell .ant-table-tbody > tr > td {
  padding: 2px 2px  !important;

}

.report-card .ant-avatar>img {
  object-fit: initial !important;
}

@media only screen and (max-width: 900px) {
  .w-md-100 {
    width: 100% !important;
  }
  .container-max-width {
    max-width: 95% !important;
  }
  .container-max-collapsed {
    max-width: 95% !important;
  }
}

/* reset password card */
/* For devices with width of 480px and less, like phones */
@media only screen and (max-width: 480px) {
  .input-lenght {
    width: 100%;
  }
}

/* For devices with width between 481px and 768px, like larger phones and portrait tablets */
@media only screen and (min-width: 481px) {
  .input-lenght {
    width: 60%;
  }
}

/* For devices with width between 769px and 1279px, like landscape tablets and laptops */
@media only screen and (min-width: 769px) {
  .input-lenght {
    width: 50%;
  }
}

/* For devices with width of 1280px and more, like desktop computers */
@media only screen and (min-width: 1280px) {
  .input-lenght {
    width: 35%;
  }
}

/* For devices with width of 1441px and more, like desktop computers */
@media only screen and (min-width: 1441px) {
  .input-lenght {
    width: 35%;
  }
}

/** bulk print **/
@media all {
  .page-break {
    display: none;
  }
  .print-element {
    font-size: 10pt !important; 
    page-break-inside: avoid; /* Prevent element from breaking across pages */
  }
}

.timetable {
  size: landscape;
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 1mm;
}
